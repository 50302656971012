@import "config";

.mat-mdc-raised-button {
    &:not([class*=mat-elevation-z]) {
        border-radius: 4px;
        letter-spacing: .5px;
        font-weight: 700;
        font-size: 1em;
        padding: 0 32px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 44px;
        text-decoration: none;

        &.dense {
            height: 32px;
            font-size: .8rem;
            line-height: 1;
        }

        &[disabled],
        &.disabled {
            color: white;
            background-color: rgba($text-default, .2);
            cursor: not-allowed;

            &.alternative-button {
                background-color: transparent;
                border: solid thin rgba($text-default, .2);
                color: rgba($text-default, .2) !important;
            }

            &.transparent-button {
                background-color: transparent;
                border: none;
                color: rgba($text-default, .2) !important;
            }

            &[disabled] {
                color: white;
            }
        }

        &:not([disabled]):not(.disabled) {
            box-shadow: none;
            border: solid thin $primary;
            background-color: $primary;
            color: $color-text-on-primary;

            &:hover {
                background-color: $primary-dark;
                border-color: $primary-dark;
            }

            &.alternative-button {
                background-color: transparent;
                border-color: $primary;
                color: $primary;

                /* Fix missing border due to rendering bugs. */
                &::after {
                    content: "";
                    width: 1px;
                    height: 0;
                    //margin-right: -1px;
                    display: inline-block;
                }

                &:hover {
                    color: $primary-dark;
                }
            }

            &.transparent-button {
                background-color: transparent;
                border: none;
                color: $primary;

                &:hover {
                    background-color: rgba($text-default, .2);
                    color: $primary-dark;
                }

                &.cancel {
                    color: $color-text-secondary;
                }
            }

            &.icon-button {
                margin: 0;
                padding: 0;
                background-color: transparent;
                border: none;
                color: $color-text-secondary;
                min-width: unset;
                width: 24px;
                height: 24px;
                box-shadow: none;

                &:hover {
                    color: $color-text-default;
                }

                &:active {
                    box-shadow: none;
                }

                .mat-icon {
                    margin: 0;
                }
            }

            &:active {
                box-shadow: none;
            }

            &.secondary-button {
                background-color: $secondary;
                border-color: $secondary;
                color: $color-text-on-secondary;

                &:hover {
                    background-color: $secondary-dark;
                    border-color: $secondary-dark;
                }

                &.alternative-button {
                    background-color: transparent;
                    border-color: $secondary;
                    color: $secondary;

                    &:hover {
                        color: $secondary-dark;
                    }
                }

                &.transparent-button {
                    background-color: transparent;
                    border: none;
                    color: $secondary;

                    &:hover {
                        background-color: rgba($text-default, .2);
                        color: $secondary-dark;
                    }
                }
            }
        }

        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
        .mat-button-wrapper {
            // width: 100%;
            //@extend .text-ellipsis;
        }
    }
}

.ups-button {
    @include flex-row(center);
    justify-content: center;
    gap: .5rem;
    padding: 0.625rem 1.5rem;
    border-radius: 2.25rem;
    font-weight: 500;
    background-color: white;
    border: solid 2px $ups-black;
    color: $ups-black;
    //min-width: 7.268rem;

    &:hover {
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06), 0 4px 12px -2px rgba(0, 0, 0, 0.08), 0 15px 32px -3px rgba(0, 0, 0, 0.08);
    }

    &.disabled,
    &[disabled] {
        pointer-events: none !important;
        border: solid 2px $ups-bray-lightest !important;
        background-color: white !important;
        color: $ups-bray-light !important;
    }

    .button-content {
        @include flex-row(center);
        gap: .5rem;
    }

    .mat-icon {
        margin-left: -.5rem;
    }

    &.primary {
        background-color: $ups-gold;
        border: solid 2px $ups-gold;
        color: $ups-black;
    }

    &.secondary {
        background-color: white;
        border: solid 2px $ups-blue-alternate;
        color: $ups-blue-alternate;

        &:hover {
            background-color: $ups-blue-hover;
        }
    }
}

.mat-mdc-unelevated-button,
.mat-mdc-outlined-button {
    font-size: 14px;
}

.mat-mdc-unelevated-button {
    --mdc-filled-button-container-height: 36px !important;
}

.mat-mdc-outlined-button {
    --mdc-outlined-button-container-height: 36px !important;
}

.auto-width {
    width: auto;
    align-self: flex-start;
}

.mdc-button {
    --mat-text-button-horizontal-padding: 16px;
    --mdc-text-button-container-height: 36px;
}

.ppi-button {
    --mdc-outlined-button-container-shape: 9999px;
    --mdc-stroked-button-container-shape: 9999px;
    --mdc-filled-button-container-shape: 9999px;

    --mat-outlined-button-horizontal-padding: 24px;
    --mat-stroked-button-horizontal-padding: 24px;
    --mat-filled-button-horizontal-padding: 24px;

    [class^="material-symbols-"] {
        width: 12px;
    }
}
